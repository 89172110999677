<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" />
    <b-form-group label="Chat ID">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="NavigationIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.chatId" placeholder="Enter Chat ID"></b-form-input>
      </b-input-group>
    </b-form-group>
    <hr />
    <div class="d-flex">
      <delete-button @click.stop="removeChatId" />
      <submit-button class="ml-auto" @click.stop="save" />
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: `ChatIdModal`,
  props: {
    clientCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loader: false,
      payload: {
        clientCode: null,
        chatId: null,
      },
    };
  },
  mounted() {
    this.loadChatId();
  },
  methods: {
    async loadChatId() {
      this.loader = true;
      try {
        this.payload.chatId = await api.get(`client/chat-id/${this.clientCode}`);
      } catch {
        this.showToast("error", "Failed to load chat id");
      } finally {
        this.loader = false;
      }
    },
    async save() {
      this.loader = true;
      try {
        this.payload.clientCode = this.clientCode;
        await api.post(`client/chat-id`, this.payload);
        this.$emit(`saveChatIdSuccess`);
      } catch {
        this.showToast("error", "Failed to save chat id");
      } finally {
        this.loader = false;
      }
    },
    async removeChatId() {
      let confirmed = await this.showConfirm("Are you sure you want to remove chat id?");
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.delete(`client/chat-id/${this.clientCode}`);
        this.$emit(`saveChatIdSuccess`);
      } catch {
        this.showToast("error", "Failed to remove chat id");
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
