<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" />

    <b-form-group label="Client Name *">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="InfoIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.description" placeholder="Enter Client Name"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="User Account Email *">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="MailIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.email" placeholder="Enter Email"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Contact Person">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="UserIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.contactPerson" placeholder="Enter Contact Person"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Whatsapp">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="PhoneIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.whatsapp" placeholder="Enter Whatsapp"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Telegram">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="NavigationIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.telegram" placeholder="Enter Telegram"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group v-if="!clientCode" label="Slot Count *">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="primary" @click.stop="decrement"><feather-icon icon="MinusIcon" /></b-button>
        </b-input-group-prepend>
        <b-form-input v-model="payload.slotCount" type="number"></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" @click.stop="increment"><feather-icon icon="PlusIcon" /></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <submit-button class="ml-auto"></submit-button>
      <cancel-button class="ml-1" @click="closeModal"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: `ClientDetail`,
  props: {
    clientCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        description: null,
        email: null,
        contactPerson: null,
        telegram: null,
        whatsapp: null,
        slotCount: 0,
      },
      loader: false,
    };
  },
  mounted() {
    if (this.clientCode) {
      this.loadClient();
    }
  },
  methods: {
    async loadClient() {
      this.loader = true;
      try {
        this.payload = await api.get(`client/${this.clientCode}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async save() {
      this.loader = true;
      try {
        let mode = this.clientCode ? "update" : "create";

        if (mode == `update`) {
          await api.put("client", this.payload);
        } else {
          await api.post("client", this.payload);
        }
        this.$emit("onSaveSuccess");
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    increment() {
      this.payload.slotCount++;
    },
    decrement() {
      if (this.payload.slotCount > 0) {
        this.payload.slotCount--;
      }
    },
  },
};
</script>
