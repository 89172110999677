<template>
  <div>
    <b-card class="main-content">
      <vue-element-loading :active="loader" />
      <template #header>
        <div>
          <b-input-group>
            <b-input-group-prepend is-text><b-icon icon="search"></b-icon></b-input-group-prepend>
            <b-form-input v-model="search" placeholder="Filter Client" debounce="500"></b-form-input>
          </b-input-group>
        </div>

        <create-button text="New Client" class="ml-auto" @click.stop="showDetail()" />
      </template>

      <b-table
        :items="table.items"
        :fields="table.fields"
        :filter="search"
        :current-page="table.paging.pageNo"
        :per-page="table.paging.pageSize"
        striped
        hover
        show-empty
      >
        <template #cell(description)="data">
          {{ pascalCase(data.item.description) }}
        </template>

        <template #cell(whatsapp)="data">
          <div class="text-center">
            <a
              v-if="data.item.whatsapp"
              :href="`https://wa.me/${data.item.whatsapp}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon icon="whatsapp" variant="success" scale="1.3" />
            </a>

            <span v-else> ~ </span>
          </div>
        </template>

        <template #cell(telegram)="data">
          <div class="text-center">
            <a
              v-if="data.item.telegram"
              :href="`https://t.me/${data.item.telegram}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon icon="telegram" variant="secondary" scale="1.3" />
            </a>

            <span v-else> ~ </span>
          </div>
        </template>

        <template #cell(telegramSetup)="data">
          <div class="text-center">
            <b-icon
              v-if="data.item.chatId"
              icon="check-circle"
              scale="1.3"
              variant="success"
              @click.stop="setChatId(data.item)"
            />
            <b-icon v-else icon="gear" variant="warning" scale="1.3" @click.stop="setChatId(data.item)" />
          </div>
        </template>

        <template #cell(slot)="data">
          <span class="text-primary" @click.stop="showList(data.item)">{{ data.item.slotCount }} Slots</span>
        </template>

        <template #cell(action)="data">
          <edit-button @click.stop="showDetail(data.item)"></edit-button>
        </template>
      </b-table>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
          <b-pagination
            v-model="table.paging.pageNo"
            :totalRows="table.items.length"
            :per-page="table.paging.pageSize"
            align="center"
            class="ml-auto"
          ></b-pagination>
        </div>
      </template>
    </b-card>

    <b-modal
      v-model="modal.detail"
      title="Client Detail"
      no-close-on-backdrop
      lazy
      centered
      hide-footer
      @hidden="onDetailClose"
    >
      <client-detail :client-code="selectedRow" @onSaveSuccess="onDetailSuccess" @onClose="modal.detail = false" />
    </b-modal>

    <b-modal
      v-if="selectedRow"
      v-model="modal.chatId"
      title="Edit Chat Id"
      size="sm"
      no-close-on-backdrop
      lazy
      centered
      hide-footer
      @hide="selectedRow = null"
    >
      <chat-id-modal :clientCode="selectedRow" @saveChatIdSuccess="saveChatIdSuccess" />
    </b-modal>

    <b-modal
      v-if="selectedRow"
      v-model="modal.nawalaList"
      :title="`Checker Slot for ${selectedRow.description}`"
      size="xl"
      no-close-on-backdrop
      lazy
      centered
      hide-footer
      @hide="onDetailClose"
    >
      <nawala-list :clientCode="selectedRow.code" @updateSlot="onNawalaListUpdateSlot" />
    </b-modal>
  </div>
</template>

<script>
import ClientDetail from "./Detail.vue";
import NawalaList from "@/views/nawala/List.vue";
import ChatIdModal from "./ChatIdModal.vue";
import api from "@api";

export default {
  name: `ClientList`,
  components: { NawalaList, ClientDetail, ChatIdModal },
  data() {
    return {
      search: null,
      selectedRow: null,
      table: {
        items: [],
        fields: [
          {
            key: `description`,
            label: `Client`,
            sortable: true,
            filterable: true,
          },
          {
            key: `contactPerson`,
            label: `PIC`,
          },
          {
            key: `whatsapp`,
            label: `Whatsapp`,
            class: `text-center`,
          },
          {
            key: `telegram`,
            label: `Telegram`,
            class: `text-center`,
          },
          {
            key: `telegramSetup`,
            label: `Notification`,
            class: `text-center`,
          },
          {
            key: `slot`,
            label: `Slot #`,
            class: `text-right`,
          },
          {
            key: "action",
            label: ``,
            class: `text-right`,
          },
        ],
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
      },
      modal: { nawalaList: false, detail: false, chatId: false },
      loader: false,
    };
  },
  computed: {},
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loader = true;
      try {
        this.table.items = await api.get(`client`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    showDetail(row) {
      if (row) this.selectedRow = row.code;
      this.modal.detail = true;
    },
    setChatId(row) {
      this.selectedRow = row.code;
      this.modal.chatId = true;
    },
    saveChatIdSuccess() {
      this.showToast(`success`, `Chat ID Saved`);
      this.modal.chatId = false;
      this.loadData();
    },
    showList(row) {
      this.selectedRow = { ...row };
      this.modal.nawalaList = true;
    },
    async onDetailSuccess() {
      this.showToast(`success`, `Client Saved`);
      this.modal.detail = false;
      this.loadData();
    },
    async onDetailClose() {
      this.selectedRow = null;
    },
    onNawalaListUpdateSlot(rec) {
      this.updateCollection(this.table.items, `username`, rec);
      this.$refs.clientTable.refresh();
    },
  },
};
</script>
